import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addPostAPIRequest,
  deletePostAPIRequest,
  editPostAPIRequest,
  getPostsAPIRequest,
  deletePostMediaAPIRequest,
} from './PostAPI';
import { AddPostProps, DeletePostMediaProps, DeletePostProps, EditPostProps } from './common';
import { setLoading } from '../../loadingSlice/loadingSlice';

interface PostState {
  // getPostsIsFetching: boolean;
  getPostsStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  getPostsResponse: any;
  getPostsError: any;

  // addPostIsFetching: boolean;
  addPostStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  addPostResponse: any;
  addPostError: any;

  // editPostIsFetching: boolean;
  editPostStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  editPostResponse: any;
  editPostError: any;

  // deletePostIsFetching: boolean;
  deletePostStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  deletePostResponse: any;
  deletePostError: any;

  // deletePostMediaIsFetching: boolean;
  deletePostMediaStatus: 'IDLE' | 'PENDING' | 'SUCCESS' | 'FAILED';
  deletePostMediaResponse: any;
  deletePostMediaError: any;
}

const initialState: PostState = {
  // getPostsIsFetching: false,
  getPostsStatus: 'IDLE',
  getPostsResponse: [],
  getPostsError: { error: { message: '' } },

  // addPostIsFetching: false,
  addPostStatus: 'IDLE',
  addPostResponse: {},
  addPostError: { error: { message: '' } },

  // editPostIsFetching: false,
  editPostStatus: 'IDLE',
  editPostResponse: {},
  editPostError: { error: { message: '' } },

  // deletePostIsFetching: false,
  deletePostStatus: 'IDLE',
  deletePostResponse: {},
  deletePostError: { error: { message: '' } },

  // deletePostMediaIsFetching: false,
  deletePostMediaStatus: 'IDLE',
  deletePostMediaResponse: {},
  deletePostMediaError: { error: { message: '' } },
};

export const getPostsRequest = createAsyncThunk('get/posts', async (_, thunkAPI) => {
  thunkAPI.dispatch(setLoading(true));
  try {
    const response = await getPostsAPIRequest();
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  } finally {
    setTimeout(() => thunkAPI.dispatch(setLoading(false)), 1000);
  }
});

export const addPostRequest = createAsyncThunk('add/post', async (addPost: { item: AddPostProps }) => {
  return await addPostAPIRequest<AddPostProps>(addPost);
});

export const editPostRequest = createAsyncThunk('edit/post', async (editPost: { item: EditPostProps }) => {
  return await editPostAPIRequest<EditPostProps>(editPost);
});

export const deletePostRequest = createAsyncThunk('delete/post', async (deletePost: { item: DeletePostProps }) => {
  return await deletePostAPIRequest<DeletePostProps>(deletePost);
});

export const deletePostMediaRequest = createAsyncThunk(
  'delete/postMedia',
  async (deletePostMedia: { item: DeletePostMediaProps }) => {
    return await deletePostMediaAPIRequest<DeletePostMediaProps>(deletePostMedia);
  }
);

const postSlice = createSlice({
  name: 'posts',
  initialState: initialState,
  reducers: {
    clearGetPostsResponse: (state) => {
      // state.getPostsIsFetching = false;
      state.getPostsStatus = 'IDLE';
      state.getPostsResponse = [];
    },
    clearAddPostResponse: (state) => {
      // state.addPostIsFetching = false;
      state.addPostStatus = 'IDLE';
      state.addPostResponse = {};
    },
    clearEditPostResponse: (state) => {
      // state.editPostIsFetching = false;
      state.editPostStatus = 'IDLE';
      state.editPostResponse = {};
    },
    clearDeletePostResponse: (state) => {
      // state.deletePostIsFetching = false;
      state.deletePostStatus = 'IDLE';
      state.deletePostResponse = {};
    },
    clearDeletePostMediaResponse: (state) => {
      // state.deletePostMediaIsFetching = false;
      state.deletePostMediaStatus = 'IDLE';
      state.deletePostMediaResponse = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPostsRequest.pending, (state) => {
        state.getPostsStatus = 'PENDING';
        // state.getPostsIsFetching = true;
      })
      .addCase(getPostsRequest.fulfilled, (state, action) => {
        // Add user to the state array
        state.getPostsStatus = 'SUCCESS';
        // state.getPostsIsFetching = false;
        state.getPostsResponse = action.payload;
      })
      .addCase(getPostsRequest.rejected, (state, error) => {
        state.getPostsStatus = 'FAILED';
        // state.getPostsIsFetching = false;
        state.getPostsError = error;
      })

      .addCase(addPostRequest.pending, (state) => {
        state.addPostStatus = 'PENDING';
        // state.addPostIsFetching = true;
      })
      .addCase(addPostRequest.fulfilled, (state, action) => {
        // Add user to the state array
        state.addPostStatus = 'SUCCESS';
        // state.addPostIsFetching = false;
        state.addPostResponse = action.payload;
      })
      .addCase(addPostRequest.rejected, (state, error) => {
        state.addPostStatus = 'FAILED';
        // state.addPostIsFetching = false;
        state.addPostError = error;
      })

      .addCase(editPostRequest.pending, (state) => {
        state.editPostStatus = 'PENDING';
        // state.editPostIsFetching = true;
      })
      .addCase(editPostRequest.fulfilled, (state, action) => {
        // Add user to the state array
        state.editPostStatus = 'SUCCESS';
        // state.editPostIsFetching = false;
        state.editPostResponse = action.payload;
      })
      .addCase(editPostRequest.rejected, (state, error) => {
        state.editPostStatus = 'FAILED';
        // state.editPostIsFetching = false;
        state.editPostError = error;
      })

      .addCase(deletePostRequest.pending, (state) => {
        state.deletePostStatus = 'PENDING';
        // state.deletePostIsFetching = true;
      })
      .addCase(deletePostRequest.fulfilled, (state, action) => {
        // Add user to the state array
        state.deletePostStatus = 'SUCCESS';
        // state.deletePostIsFetching = false;
        state.deletePostResponse = action.payload;
      })
      .addCase(deletePostRequest.rejected, (state, error) => {
        state.deletePostStatus = 'FAILED';
        // state.deletePostIsFetching = false;
        state.deletePostError = error;
      })

      // DeletePostMedia
      .addCase(deletePostMediaRequest.pending, (state) => {
        state.deletePostMediaStatus = 'PENDING';
      })
      .addCase(deletePostMediaRequest.fulfilled, (state, action) => {
        state.deletePostMediaStatus = 'SUCCESS';
        state.deletePostMediaResponse = action.payload;
      })
      .addCase(deletePostMediaRequest.rejected, (state, error) => {
        state.deletePostMediaStatus = 'FAILED';
        state.deletePostMediaError = error;
      });
  },
});

export const buddyFeedDataSelector = (state: RootState) => state.buddyFeedData;

export const {
  clearGetPostsResponse,
  clearAddPostResponse,
  clearEditPostResponse,
  clearDeletePostResponse,
  clearDeletePostMediaResponse,
} = postSlice.actions;

export default postSlice.reducer;
