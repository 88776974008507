import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import type { SelectProps } from 'antd';
import '../style.scss';
import './common_form.scss';
import 'react-phone-number-input/style.css';
import PhoneInput, { Value } from 'react-phone-number-input';
import { LanguageProps } from '../../../../Models/LanguageProps';
import toCamelCase, { toCamelCaseWord } from '../../../../utils/stringFormat/CapsTocamel';
import moment from 'moment';
import 'antd/es/date-picker/style/css';
import { studentPersonalInfoFields } from './validation';
import { useState } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { isMobile } from 'react-device-detect';

const { TextArea } = Input;
const { Option } = Select;

export const PersonalInfo = ({
  data,
  userData,
  onChangeForm,
  interestsData,
  languagesData,
  countryData,
  statesData,
  errorObject,
  removeError,
  phoneCode,
  subdomain,
  whatsappChecked,
  setWhatsappChecked,
  formData,
  stateEnabled,
}: any) => {
  //   console.log("=== languagesData ===", languagesData);

  // Search Feature
  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  const filterOption = (input: string, option?: { label: string; value: string }) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <>
      <Form className="form" layout="vertical">
        {isMobile ? (
          <div>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="My role">
                  <span className="ant-form-text">
                    {userData?.type ? `${toCamelCaseWord(userData?.type)} Ambassador` : ''}
                  </span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Display Name">
                  {/* <span className="ant-form-text"> */}
                  <Input
                    className="input"
                    value={formData?.displayName}
                    onChange={(e: any) => {
                      onChangeForm('displayName', e.target.value);
                      removeError('displayName');
                    }}
                  />
                  {/* </span> */}
                  {studentPersonalInfoFields?.displayName && errorObject?.displayName ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Phone number">
                  <PhoneInput
                    placeholder="Enter phone number"
                    className="input ant-input "
                    international
                    value={formData?.mobileNumber}
                    onChange={(value: any) => {
                      onChangeForm('mobileNumber', value);
                      removeError('mobileNumber');
                    }}
                    defaultCountry={phoneCode}
                  />
                  {studentPersonalInfoFields?.mobileNumber && errorObject?.mobileNumber ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="">
                  <Checkbox
                    defaultChecked={whatsappChecked}
                    value={whatsappChecked}
                    onChange={(e: CheckboxChangeEvent) => setWhatsappChecked(e.target.checked)}
                  />{' '}
                  This is also my whatsapp number
                </Form.Item>
                {!whatsappChecked ? (
                  <Form.Item label="Whatsapp number">
                    <PhoneInput
                      placeholder="Enter phone number"
                      className="input ant-input "
                      international
                      value={formData?.whatsapp}
                      onChange={(value: any) => {
                        onChangeForm('whatsapp', value);
                        // removeError("whatsapp");
                      }}
                      defaultCountry={phoneCode}
                    />
                  </Form.Item>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Interests">
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder=""
                    optionLabelProp="label"
                    value={formData?.interests}
                    onChange={(value: any) => {
                      onChangeForm('interests', value);
                      removeError('interests');
                    }}
                    options={interestsData}
                  />
                  {studentPersonalInfoFields?.interests && errorObject?.interests ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Languages">
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select language"
                    optionLabelProp="label"
                    onSearch={onSearch}
                    filterOption={filterOption}
                    value={formData?.languages}
                    onChange={(value: any) => {
                      onChangeForm('languages', value);
                      removeError('languages');
                    }}
                    options={languagesData}
                  />
                  {studentPersonalInfoFields?.languages && errorObject?.languages ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Which country are you from ?">
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a country"
                    optionLabelProp="label"
                    onSearch={onSearch}
                    filterOption={filterOption}
                    value={formData?.countryCode}
                    onChange={(value: any) => {
                      onChangeForm('countryCode', value);
                      removeError('countryCode');
                    }}
                    options={countryData}
                  />
                  {studentPersonalInfoFields?.countryCode && errorObject?.countryCode ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
              {/* State Register Mobile */}
              {stateEnabled && (
                <Col xs={24} sm={12} className="profile-input">
                  <Form.Item label="Which state are you from?">
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a state"
                      optionLabelProp="label"
                      onSearch={onSearch}
                      // disabled={!stateEnabled}
                      filterOption={filterOption}
                      value={formData?.stateId}
                      onChange={(value: any) => {
                        onChangeForm('stateId', value);
                        removeError('stateId');
                      }}
                      options={statesData}
                    />
                    {studentPersonalInfoFields?.stateId && errorObject?.stateId ? (
                      <span style={{ color: 'red' }}>Required</span>
                    ) : null}
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} className="profile-input">
                <Form.Item label="About">
                  <TextArea
                    showCount
                    maxLength={100}
                    value={formData?.description}
                    onChange={(e: any) => {
                      onChangeForm('description', e.target.value);
                      removeError('description');
                    }}
                    placeholder="Share some interesting facts about you"
                    style={{ height: 120, resize: 'none' }}
                  />
                  {studentPersonalInfoFields?.description && errorObject?.description ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="My role">
                  <span className="ant-form-text">
                    {userData?.type ? `${toCamelCaseWord(userData?.type)} Ambassador` : ''}
                  </span>
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Interests">
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder=""
                    optionLabelProp="label"
                    value={formData?.interests}
                    onChange={(value: any) => {
                      onChangeForm('interests', value);
                      removeError('interests');
                    }}
                    options={interestsData}
                  />
                  {studentPersonalInfoFields?.interests && errorObject?.interests ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Display Name">
                  {/* <span className="ant-form-text"> */}
                  <Input
                    className="input"
                    value={formData?.displayName}
                    onChange={(e: any) => {
                      onChangeForm('displayName', e.target.value);
                      removeError('displayName');
                    }}
                  />
                  {/* </span> */}
                  {studentPersonalInfoFields?.displayName && errorObject?.displayName ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Languages">
                  <Select
                    showSearch
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="Select language"
                    optionLabelProp="label"
                    onSearch={onSearch}
                    filterOption={filterOption}
                    value={formData?.languages}
                    onChange={(value: any) => {
                      onChangeForm('languages', value);
                      removeError('languages');
                    }}
                    options={languagesData}
                  />
                  {studentPersonalInfoFields?.languages && errorObject?.languages ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Phone number">
                  <PhoneInput
                    placeholder="Enter phone number"
                    className="input ant-input "
                    international
                    value={formData?.mobileNumber}
                    onChange={(value: any) => {
                      onChangeForm('mobileNumber', value);
                      removeError('mobileNumber');
                      if (errorObject?.sameNumber) {
                        removeError('sameNumber');
                      }
                    }}
                    defaultCountry={phoneCode}
                  />
                  {studentPersonalInfoFields?.mobileNumber && errorObject?.mobileNumber ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="Which country are you from?">
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Select a country"
                    optionLabelProp="label"
                    onSearch={onSearch}
                    filterOption={filterOption}
                    value={formData?.countryCode}
                    onChange={(value: any) => {
                      onChangeForm('countryCode', value);
                      removeError('countryCode');
                    }}
                    options={countryData}
                  />
                  {studentPersonalInfoFields?.countryCode && errorObject?.countryCode ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
              {/* State Register Web */}
              {stateEnabled && (
                <Col xs={24} sm={12} className="profile-input">
                  <Form.Item label="Which state are you from?">
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="Select a state"
                      optionLabelProp="label"
                      onSearch={onSearch}
                      // disabled={!stateEnabled}
                      filterOption={filterOption}
                      value={formData?.stateId}
                      onChange={(value: any) => {
                        onChangeForm('stateId', value);
                        removeError('stateId');
                      }}
                      options={statesData}
                    />
                    {studentPersonalInfoFields?.stateId && errorObject?.stateId ? (
                      <span style={{ color: 'red' }}>Required</span>
                    ) : null}
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={12} className="profile-input">
                <Form.Item label="">
                  <Checkbox
                    defaultChecked={whatsappChecked}
                    value={whatsappChecked}
                    onChange={(e: CheckboxChangeEvent) => setWhatsappChecked(e.target.checked)}
                  />{' '}
                  This is also my whatsapp number
                </Form.Item>
                {!whatsappChecked ? (
                  <Form.Item label="Whatsapp number">
                    <PhoneInput
                      placeholder="Enter phone number"
                      className="input ant-input "
                      international
                      value={formData?.whatsapp}
                      onChange={(value: any) => {
                        onChangeForm('whatsapp', value);
                        // removeError("whatsapp");
                        if (errorObject?.sameNumber) {
                          removeError('sameNumber');
                        }
                      }}
                      defaultCountry={phoneCode}
                    />
                    {errorObject?.sameNumber ? (
                      <span style={{ color: 'red' }}>You've entered the same number for both fields</span>
                    ) : (
                      <></>
                    )}
                  </Form.Item>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col xs={24} sm={24} className="profile-input">
                <Form.Item label="About">
                  <TextArea
                    showCount
                    maxLength={100}
                    value={formData?.description}
                    onChange={(e: any) => {
                      onChangeForm('description', e.target.value);
                      removeError('description');
                    }}
                    placeholder="Share some interesting facts about you"
                    style={{ height: 120, resize: 'none' }}
                  />
                  {studentPersonalInfoFields?.description && errorObject?.description ? (
                    <span style={{ color: 'red' }}>Required</span>
                  ) : (
                    <></>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </>
  );
};
