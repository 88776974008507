import { Checkbox, Col, DatePicker, Form, Input, Row, Select, message } from 'antd';
import type { SelectProps } from 'antd';
import '../style.scss';
import './common_form.scss';
import 'react-phone-number-input/style.css';
import PhoneInput, { Value } from 'react-phone-number-input';
import { LanguageProps } from '../../../../Models/LanguageProps';
import toCamelCase, { toCamelCaseWord } from '../../../../utils/stringFormat/CapsTocamel';
import moment from 'moment';
import 'antd/es/date-picker/style/css';
import { useEffect, useState } from 'react';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { studentProfFields } from './validation';
import { registerResponseSelector } from '../RegisterSlice';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import RegisterSuccessModel from '../../../../common/RegisterSuccessModel';
import { AuthPropType, useAuth } from '../../../../components/AuthProvider/AuthoProvider';

const { Option } = Select;

const graduationYears = [
  { value: 'jack', label: 'Jack' },
  { value: 'lucy', label: 'Lucy' },
  { value: 'Yiminghe', label: 'yiminghe' },
  { value: 'disabled', label: 'Disabled', disabled: true },
];

export const ProfessionalInfo = ({
  data,
  programmeData,
  yearOfStudy,
  userData,
  onChangeForm,
  errorObject,
  removeError,
  formData,
  uniPublicData,
}: any) => {
  const [courses, setCourses] = useState<any>([]);
  const [selectedProgramme, setSelectedProgramme] = useState<any>();
  const [selectedCourse, setSelectedCourse] = useState<any>();
  const { registerStatus } = useAppSelector(registerResponseSelector);
  const [registerSuccessModel, setRegisterSuccessModel] = useState<boolean>(false);
  const { logout }: AuthPropType = useAuth();

  useEffect(() => {
    if (formData?.programId && programmeData && courses?.length === 0) {
      let courseArr: any = [];
      let filteredCourses =
        data &&
        data?.programmes.filter((el) => {
          return el?.id === formData?.programId;
        });

      for (let index = 0; index < filteredCourses[0].courses.length; index++) {
        const element = filteredCourses[0].courses[index];
        let obj = {
          value: element?.id,
          label: element?.name,
        };
        courseArr.push(obj);
      }

      setCourses(courseArr);
    }
  }, [formData]);

  const handleProgrammeChange = (value: string) => {
    let courseArr: any = [];
    setSelectedProgramme(value);
    onChangeForm('programId', value);
    onChangeForm('courseId', null);
    let filteredCourses =
      data &&
      data?.programmes.filter((el) => {
        return el?.id === value;
      });

    for (let index = 0; index < filteredCourses[0].courses.length; index++) {
      const element = filteredCourses[0].courses[index];
      let obj = {
        value: element?.id,
        label: element?.name,
      };
      courseArr.push(obj);
    }

    setCourses(courseArr);
  };

  const disbaleDates = (current: any, disable: string) => {
    if (disable === 'future') {
      let customDate = moment().format('YYYY-MM-DD');
      return current && current > moment(customDate, 'YYYY-MM-DD');
    } else {
      let customDate = moment().format('YYYY-MM-DD');
      return current && current < moment(customDate, 'YYYY-MM-DD');
    }
  };

  useEffect(() => {
    if (registerStatus === 'SUCCESS') {
      setRegisterSuccessModel(true);
    }
  }, [registerStatus]);

  return (
    <>
      <Form className="form" layout="vertical">
        {(userData?.type === 'STUDENT' || userData?.type === 'ALUMNI') && (
          <Row gutter={16}>
            <Col xs={24} sm={12} className="profile-input">
              <Form.Item label="Programme">
                <Select
                  style={{ width: '100%' }}
                  placeholder=""
                  optionLabelProp="label"
                  onChange={handleProgrammeChange}
                  value={formData?.programId}
                  options={programmeData}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} className="profile-input">
              <Form.Item label="Course">
                <Select
                  style={{ width: '100%' }}
                  placeholder=""
                  optionLabelProp="label"
                  onChange={(value) => {
                    onChangeForm('courseId', value);
                    setSelectedCourse(value);
                  }}
                  value={formData?.courseId || undefined}
                  options={courses}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {userData?.type === 'STUDENT' && (
          <Row gutter={16}>
            <Col xs={24} sm={12} className="profile-input">
              <Form.Item label="Year of study">
                <Select
                  style={{ width: '100%' }}
                  placeholder=""
                  optionLabelProp="label"
                  onChange={(value) => {
                    onChangeForm('year', value);
                  }}
                  value={formData?.year}
                  options={yearOfStudy}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} className="profile-input">
              <Form.Item label="Expected graduation year">
                <DatePicker
                  // className="year-picker"
                  style={{ borderRadius: '5px', height: '40px', width: '100%' }}
                  // defaultValue={input.value ? moment.utc(input.value, "YYYY-MM-DD") : undefined}
                  picker="year"
                  disabledDate={(current) => disbaleDates(current, 'past')}
                  format={'YYYY'}
                  onChange={(value: any) => {
                    onChangeForm('yearOfGraduation', value);
                  }}
                  defaultValue={
                    formData?.yearOfGraduation ? moment.utc(formData?.yearOfGraduation, 'YYYY-MM-DD') : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col xs={24} sm={12} className="profile-input">
            {userData?.type !== 'STUDENT' && (
              <Form.Item label="Current role">
                {/* <span className="ant-form-text"> */}
                <Input
                  className="input"
                  onChange={(e: any) => {
                    onChangeForm('role', e.target.value);
                    removeError('role');
                  }}
                  value={formData?.role}
                />
                {/* </span> */}
                {errorObject?.role ? <div style={{ color: 'red' }}>Required</div> : <></>}
              </Form.Item>
            )}
          </Col>
          <Col xs={24} sm={12} className="profile-input">
            {(userData?.type === 'ALUMNI' || userData?.type === 'PARENT') &&
            (userData?.type !== 'STUDENT' || userData?.type !== 'STAFF') ? (
              <Form.Item label="Company name">
                <Input
                  className="input"
                  onChange={(e: any) => {
                    onChangeForm('companyName', e.target.value);
                    removeError('companyName');
                  }}
                  value={formData?.companyName}
                />
                {errorObject?.companyName ? <div style={{ color: 'red' }}>Required</div> : <></>}
              </Form.Item>
            ) : (
              <>
                {userData?.type === 'STAFF' && (
                  <Form.Item label="Department">
                    <Input
                      className="input"
                      onChange={(e: any) => {
                        onChangeForm('department', e.target.value);
                        removeError('department');
                      }}
                      value={formData?.department}
                    />
                    {errorObject?.department ? <div style={{ color: 'red' }}>Required</div> : <></>}
                  </Form.Item>
                )}
              </>
            )}
          </Col>
        </Row>

        {userData?.type === 'ALUMNI' && (
          <Row gutter={16}>
            <Col xs={24} sm={12} className="profile-input">
              <Form.Item label="Graduated year">
                <DatePicker
                  // className="year-picker"
                  style={{ borderRadius: '5px', height: '40px', width: '100%' }}
                  // defaultValue={input.value ? moment.utc(input.value, "YYYY-MM-DD") : undefined}
                  onChange={(value: any) => {
                    onChangeForm('yearOfGraduation', value);
                  }}
                  picker="year"
                  disabledDate={(current) => disbaleDates(current, 'future')}
                  format={'YYYY'}
                  defaultValue={
                    formData?.yearOfGraduation ? moment.utc(formData?.yearOfGraduation, 'YYYY-MM-DD') : undefined
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={16}>
          <Col xs={24} sm={12} className="profile-input">
            <Form.Item label="">
              <Checkbox
                onChange={(e: CheckboxChangeEvent) => {
                  onChangeForm('agreement', e.target.checked);
                  removeError('agreement');
                }}
                defaultChecked={formData?.agreement}
              />{' '}
              {`I agree to  ${userData?.type ? `${toCamelCaseWord(userData?.type)} Ambassador` : ''}`}
              <a
                href={'https://static.univiser.io/common/policy_terms_docs/buddy_user_terms_and_conditions.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </a>{' '}
              and{' '}
              <a
                href={'https://static.univiser.io/common/policy_terms_docs/privacy_policy.pdf'}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              .
              {studentProfFields?.agreement && errorObject?.agreement ? (
                <div style={{ color: 'red' }}>Required</div>
              ) : (
                <></>
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <RegisterSuccessModel uniPublicData={uniPublicData} logout={logout} visible={registerSuccessModel} />
    </>
  );
};
